/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
* {
  font-family: Poppins, Muli, sans-serif;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
}
.example-container {
  background-color: white;
}
.header {
  position: relative;
  width: 100%;
  // height: 5rem;
  left: 0;
  align-items: center;
  background: #fff;
  box-shadow: 0 4px 4px #0000001a;
  display: flex;
  z-index: 999;
}
.fixed-header {
  position: sticky !important;
  z-index: 999;
  top: 0;
}
#header .mat-form-field-underline {
  display: none;
}
#header .mat-form-field-wrapper {
  padding-bottom: 0;
}
#header .mat-select-arrow {
  color: #ff9933;
}
#header .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(0);
}
#header .mat-form-field-infix {
  border-top: 0.23343em;
}
#header .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px;
}

#header .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
// #header .mat-form-field-label-wrapper {
//   display: none;
// }
#header .mat-form-field.w-100 .mat-form-field-flex {
  padding: 0.75em 0.75em 0.25em 0.75em !important;
  border-radius: 4px;
}

#booknow .mat-form-field-underline {
  display: none;
}
#booknow .mat-form-field-wrapper {
  padding-bottom: 0;
}
#booknow .mat-select-arrow {
  color: #ff9933;
}
#booknow .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(0);
}
#booknow .mat-form-field-infix {
  // padding-top: 0.55555em !important;
  border-top: 0.23343em;
}
#booknow .mat-datepicker-toggle-default-icon {
  color: #ff9933;
}
#booknow .css .mat-form-field-flex {
  padding-top: 0;
}
.book-now-btn {
  display: flex;
  justify-content: end;
  padding: 0.2rem 2.4rem;
  background-color: #138808;
  color: #ffffff;
}
.book-now-form-row {
  padding: 1rem 1rem;
}
.confirmed-modal {
  .first {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #ff9933;
  }
  .second {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #333333;
  }
  .button {
    padding: 0.7rem 3rem;
    background: #138808;
    border-radius: 4px;
    color: #ffffff;
  }
}

agm-map {
  height: 100vh !important;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
  .search-btn {
    padding: 0.4rem 3rem !important;
  }
  .book-now-form-row {
    padding: 0;
  }
}
.agm-map-container-inner {
  border-radius: 4px;
}
#header .row {
  margin-right: 0 !important;
}
.confirmedclass {
  margin: 0 auto;
  text-align: center;
  max-width: 70vw !important;
}
.wrapper .mainContainer {
  // padding: 3.5em 0 0.5em;
  display: inline-block;
  width: 100%;
  min-height: calc(100vh - 64px);
  overflow: hidden;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}
.leftMenu {
  width: 200px;
  // background-color: #f9f9fc;
  // padding-top: 10px;
  background: #f9f9fc;
  color: #138808;
  // padding-top: 50px;
  // border-image-source: "~assets/img/blr.png";
  // border-image-slice: 20 20;
  // border-image-repeat: round;
}
